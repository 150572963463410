import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import {
  AccountCircle,
  RemoveRedEye,
  Login as LoginIcon,
  Email,
  Lock,
  HourglassBottom,
  Logout,
} from "@mui/icons-material";
import FormLogo from "./formLogo";
import Footer from "./footer";
import { showToast } from "../utils/toasts";
import useToken from "../hooks/useToken";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { UserEndpoint } from "../utils/ApiHandler";
import { authActions } from "../store/auth";
import { useCookies } from "react-cookie";
import useRedirect from "../utils/useRedirect";
export default function Login(props) {
  let { appredirectUrl } = useRedirect();
  let { changeView, resetView, appUser, disposeUser } = props;
  let { LoginUser } = UserEndpoint();
  const [cookies, setCookie] = useCookies(["GLOBAL_TECHKEY_AUTH"]);
  let history = useHistory();
  let dispatch = useDispatch();
  let [isloading, setIsLoading] = useState(false);
  let [email, setEmail] = useState("");
  let [password, setpassword] = useState("");
  let [emailError, setemailError] = useState({ status: false, message: "" });
  let [passwordError, setpasswordError] = useState({
    status: false,
    message: "",
  });

  function showError(message, errorType, pass) {
    if (!pass) {
      showToast({
        message: message,
        type: "danger",
      });
    }
    errorType(() => {
      return { status: !pass, message: message };
    });
  }

  async function submitHandler(event) {
    event.preventDefault();

    if (isloading) {
      return;
    }

    setIsLoading(true);

    if (email.length <= 0) {
      showError("Email is required", setemailError);
      setIsLoading(false);
      return;
    }
    showError("", setemailError, true);

    if (password.length < 8) {
      showError("Password length must to be greater than 8", setpasswordError);
      setIsLoading(false);
      return;
    }
    showError("", setpasswordError, true);

    try {
      console.log({
        email,
        password,
      });
      let res = await LoginUser({
        email,
        password,
      });

      if (res.data.status === "verify") {
        history.push(`/regVerify?input=${res.data.id}`);

        showToast({
          message: "Please verify your account",
        });
        setIsLoading(false);
        return;
      }

      if (res.status === 200 && res.data.status === "success") {
        setIsLoading(false);
        showToast({
          message: "Login success",
        });

        dispatch(
          authActions.logUserIn({
            token: res.data.token,
            login: res.data.status === "success",
          })
        );

        setCookie("GLOBAL_TECHKEY_AUTH", res.data.token, {
          path: "/",
          expires: new Date(Date.now() + "30" * 60 * 60 * 60 * 60 * 60),
          secure: false,
          httpOnly: false,
        });

        return;
      }

      setIsLoading(false);

      if (res.data.message.includes("Invalid Email or Password")) {
        return showError("Invalid Email or password", setemailError);
      }
      showError("", setpasswordError, true);
      showError("", setemailError, true);

      let error = res.data.message || "Login  failed, try again later";

      showToast({
        message: error,
        type: "danger",
      });
    } catch (error) {
      console.log("TC-0909", error);
      setIsLoading(false);
      showToast({
        message: "An error occured, try again later",
        type: "danger",
      });
    }
  }

  return (
    <form action="index.html" autoComplete="off" className="sign-in-form ">
      <FormLogo />

      <div className="heading">
        <h2>Welcome Back</h2>
        <br />
        {!appUser && <h6>Not registred?</h6>}
        {!appUser && (
          <a
            onClick={function (e) {
              e.preventDefault();
              changeView();
            }}
            href="#"
            className="toggle"
          >
            {" "}
            Sign up
          </a>
        )}

        {appUser && <h6>You are Logged in.</h6>}
      </div>

      <div className="actual-form">
        {!appUser && (
          <Fragment>
            <div className="input-wrap">
              <input
                type="email"
                minLength="4"
                className={`input-field ${email.length > 0 ? "active" : ""} ${
                  emailError.status ? "inputerror" : ""
                } `}
                autoComplete="off"
                required
                value={email}
                onChange={function (e) {
                  let value = e.target.value;
                  setEmail(value);
                }}
              />
              <label>Email</label>
            </div>

            <div style={{ marginBottom: "10px" }} className="input-wrap">
              <input
                type="password"
                minLength="4"
                className={`input-field ${
                  password.length > 0 ? "active" : ""
                } ${passwordError.status ? "inputerror" : ""} `}
                autoComplete="off"
                value={password}
                required
                onChange={function (e) {
                  let value = e.target.value;
                  setpassword(value);
                }}
              />
              <label>Password</label>
            </div>
            <p className="text forgot-text">
              <a
                onClick={function (e) {
                  e.preventDefault();
                  resetView();
                }}
                className="reset-pass"
                href="#"
              >
                Reset password
              </a>
            </p>

            <button onClick={submitHandler} className="flex-con sign-btn">
              {!isloading && (
                <Fragment>
                  Sign In <LoginIcon />
                </Fragment>
              )}
              {isloading && "Loading... "}
              {isloading && <HourglassBottom />}
            </button>
          </Fragment>
        )}

        {appUser && (
          <Fragment>
            <button
              onClick={function (e) {
                e.preventDefault();
                window.open(appredirectUrl, "_self");
              }}
              className="flex-con sign-btn"
            >
              Continue as {appUser.name.split(" ")[0]} <AccountCircle />
            </button>
            <hr />
            <br />
            <button
              onClick={function (e) {
                e.preventDefault();
                disposeUser();
              }}
              className="flex-con sign-btn"
            >
              Logout <Logout />
            </button>
          </Fragment>
        )}

        <Footer />
      </div>
    </form>
  );
}
