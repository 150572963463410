import { Fragment, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom.min";
import Auth from "./pages/auth";
import { useDispatch, useSelector } from "react-redux";
import "animate.css";
import "react-toastify/dist/ReactToastify.css";
import { useCookies } from "react-cookie";
import { UserEndpoint } from "./utils/ApiHandler";
import useRedirect from "./utils/useRedirect";
import { authActions } from "./store/auth";
function App() {
  let [isloading, setIsLoading] = useState(false);
  let { validateLoginUser } = UserEndpoint();
  let [appUser, setappUser] = useState();
  let dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(["GLOBAL_TECHKEY_AUTH"]);
  useRedirect();
  function disposeUser() {
    setappUser(null);
  }
  useEffect(
    function () {
      async function validateToken() {
        try {
          if (cookies.GLOBAL_TECHKEY_AUTH) {
            setIsLoading(true);

            setTimeout(() => {
              setIsLoading(false);
            }, 7000);
            let res = await validateLoginUser({
              token: cookies.GLOBAL_TECHKEY_AUTH,
            });

            if (res.data.status === "authenticated") {
              setappUser({
                name: res.data.name,
                token: res.data.token,
              });

              dispatch(
                authActions.logUserIn({
                  token: res.data.token,
                  login: false,
                })
              );
            }
            setIsLoading(false);
          }
        } catch (error) {
          console.log("TC-98766", error);
          setIsLoading(false);
        }
      }

      validateToken();
    },
    [cookies.GLOBAL_TECHKEY_AUTH]
  );

  return (
    <BrowserRouter>
      <Fragment>
        <Auth disposeUser={disposeUser} appUser={appUser} />
      </Fragment>
    </BrowserRouter>
  );
}

export default App;
