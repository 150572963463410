import { Fragment, useCallback, useState } from "react";
import GitHubLogin from "react-github-login";
import { GoogleLogin } from "react-google-login";
import useToken from "../hooks/useToken";
import { UserEndpoint } from "../utils/ApiHandler";
import { showToast } from "../utils/toasts";
import { GitHub } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { authActions } from "../store/auth";
import { useCookies } from "react-cookie";
let googleClientID =
  "390511252259-23aov48okb9m7qslqd9q9svlos9p42pq.apps.googleusercontent.com";

export default function Footer(props) {
  const [cookies, setCookie] = useCookies(["GLOBAL_TECHKEY_AUTH"]);

  let { addGoogleAuthUser, addGitAuthUser } = useCallback(UserEndpoint(), []);
  let [Loading, setloading] = useState(false);
  let { loguserIn } = useToken();

  let dispatch = useDispatch();
  async function gitSuccess(response) {
    setloading(true);
    showToast({
      message: "Please wait, while we verify your account",
    });
    let data = {
      code: response.code,
    };

    let res = await addGitAuthUser(data);

    if (res.status === 200 && res.data.status === "success") {
      setloading(false);
      showToast({
        message: "Login success",
      });
      dispatch(
        authActions.logUserIn({
          token: res.data.token,
          login: res.data.status === "success",
        })
      );

      setCookie("GLOBAL_TECHKEY_AUTH", res.data.token, {
        path: "/",
        expires: new Date(Date.now() + "30" * 60 * 60 * 60 * 60 * 60),
        secure: true,
        httpOnly: false,
      });

      return;
    }
    showToast({
      message: "Could not validate your account with github",
      type: "error",
    });

    setloading(false);
  }

  function gitError(response) {
    console.log("TC-96447", response);
  }
  function signOut(error) {
    console.log("TC-7192", error);
  }

  async function onSignIn(googleUser) {
    setloading(true);
    showToast({
      message: "Please wait, while we verify your account",
    });
    let data = {
      token: googleUser.tokenId,
      name: googleUser.profileObj.name,
      email: googleUser.profileObj.email,
      photo: googleUser.profileObj.imageUrl,
    };
    let res = await addGoogleAuthUser(data);
    if (res.status === 200 && res.data.status === "success") {
      setloading(false);
      showToast({
        message: "Login success",
      });

      dispatch(
        authActions.logUserIn({
          token: res.data.token,
          login: res.data.status === "success",
        })
      );

      setCookie("GLOBAL_TECHKEY_AUTH", res.data.token, {
        path: "/",
        expires: new Date(Date.now() + "30" * 60 * 60 * 60 * 60 * 60),
        secure: true,
        httpOnly: false,
      });

      return;
    }
    showToast({
      message: "Could not validate your account with google",
      type: "error",
    });

    setloading(false);
  }

  return (
    <Fragment>
      <p className="divider">or continue with</p>
      <div className="action-btns">
        <GoogleLogin
          clientId={googleClientID}
          buttonText="Login"
          onSuccess={onSignIn}
          onFailure={signOut}
          className="gSignin "
          cookiePolicy={"single_host_origin"}
          isSignedIn={false}
        />

        <GitHubLogin
          clientId="Iv1.0c2faec5e8420f8c"
          onSuccess={gitSuccess}
          redirectUri=""
          onFailure={gitError}
          className="metroRegular gitBtn"
        >
          <i className="mdi mr-1 mdi-github mdi-18px"></i>
          GitHub <GitHub />
        </GitHubLogin>
      </div>
      <p className="text">
        By using our platforms, you agree to our{" "}
        <a
          target={"_blank"}
          className="terms-link"
          href="https://techkey.co.ke/terms"
        >
          Terms of Services
        </a>{" "}
        and{" "}
        <a
          target={"_blank"}
          className="terms-link"
          href="https://techkey.co.ke/policy"
        >
          Privacy Policy
        </a>
      </p>
      <p style={{ textAlign: "center" }} className="text">
        V1.1.2
      </p>
    </Fragment>
  );
}
