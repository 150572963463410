import { useEffect, useRef } from "react";

export default function Carousel(props) {
  let text1 = useRef();
  let text2 = useRef();
  let text3 = useRef();
  let textgroup = useRef();
  let bullet1 = useRef();
  let bullet2 = useRef();
  let bullet3 = useRef();

  let imgone = useRef();
  let imgtwo = useRef();
  let imgthree = useRef();
  let imageRefs = [imgone, imgtwo, imgthree];
  let textRefs = [text1, text2, text3];

  let images = [
    {
      name: "img-1",
      img: "image2.png",
    },
    {
      name: "img-0",
      img: "image1.png",
    },
    {
      name: "img-2",
      img: "image3.png",
    },
  ];

  let texts = [
    "Manage just One Account",
    "System Wide Authentication",
    "Access All Techkey Platforms",
  ];
  let bullets = [0, 1, 2];
  let bulletRefs = [bullet1, bullet2, bullet3];
  function changeSlider(e, pass, customtarget) {
    if (!pass) {
      e.preventDefault();
    }
    let target = e.target || customtarget;
    let index = target.dataset.value;
    target.ref = bulletRefs[index];

    imageRefs.forEach(function (images) {
      if (!images.current) {
        return;
      }
      images.current.classList.remove("show");
    });
    let currentImage;
    images.forEach(function (item, funcindex) {
      if (item.name == `img-${index}`) {
        currentImage = imageRefs[funcindex];
        return;
      }
    });
    if (!currentImage) {
      return;
    }
    if (!currentImage.current) {
      return;
    }
    currentImage.current.classList.add("show");
    text1.current.innerHTML = texts[index];

    bulletRefs.forEach((bull) => bull.current.classList.remove("active"));
    target.ref.current.classList.add("active");
  }

  let start = 0;
  let appTimeout = null;
  useEffect(function () {
    if (appTimeout) {
      clearTimeout(appTimeout);
    }
    function autoCarousel(data) {
      appTimeout = setTimeout(() => {
        if (start == 3) {
          start = 0;
        }

        changeSlider("", true, {
          dataset: {
            value: start,
          },
          ref: bulletRefs[start],
        });
        start++;
        autoCarousel();
      }, 4500);
    }

    autoCarousel();
  }, []);
  return (
    <div className="carousel">
      <div className="images-wrapper">
        {images.map(function (item, index) {
          return (
            <img
              ref={imageRefs[index]}
              key={index}
              src={`./img/${item.img}`}
              className={`image ${item.name} ${index == 0 ? "show" : ""}`}
              alt=""
            />
          );
        })}
      </div>

      <div className="text-slider">
        <div className="text-wrap">
          <div ref={textgroup} className="text-group">
            <h2 ref={text1}>{texts[0]}</h2>
          </div>
        </div>

        <div className="bullets">
          {bullets.map(function (item, index) {
            return (
              <span
                ref={bulletRefs[index]}
                // onClick={changeSlider}
                className={index == 0 ? "active" : ""}
                key={index}
                data-value={item}
              ></span>
            );
          })}
        </div>
      </div>
    </div>
  );
}
