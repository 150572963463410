import { toast } from "react-toastify";
export let showToast = function (data) {
  let { type = "success", message = "" } = data;
  let options = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  if (type == "success") {
    toast.success(message, options);
    return;
  }

  toast.warn(message, options);
};
