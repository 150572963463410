import axios from "axios";
import { APIURL } from "../store/auth";

export function UserEndpoint(userEndPoint = "users") {
  function internetController() {
    return window.navigator.onLine;
  }

  async function registerUser(data) {
    let controll = internetController();
    if (!controll) {
      return false;
    }
    return await axios({
      method: "post",
      url: `${APIURL}/${userEndPoint}`,
      data,
    });
  }

  async function verifyUser(data) {
    let controll = internetController();
    if (!controll) {
      return false;
    }
    return await axios({
      method: "post",
      url: `${APIURL}/${userEndPoint}/verify`,
      data,
    });
  }

  async function resendVerification(data) {
    let controll = internetController();
    if (!controll) {
      return false;
    }
    return await axios({
      method: "post",
      url: `${APIURL}/${userEndPoint}/resendVerification`,
      data,
    });
  }

  async function LoginUser(data) {
    let controll = internetController();
    if (!controll) {
      return false;
    }
    return await axios({
      method: "post",
      url: `${APIURL}/${userEndPoint}/login`,
      data,
    });
  }

  async function resetPassword(data) {
    let controll = internetController();
    if (!controll) {
      return false;
    }
    return await axios({
      method: "post",
      url: `${APIURL}/${userEndPoint}/forgotPassword/global`,
      data,
    });
  }

  async function setNewPassword(data) {
    let controll = internetController();
    if (!controll) {
      return false;
    }
    return await axios({
      method: "post",
      url: `${APIURL}/${userEndPoint}/resetpassword/${data.token}`,
      data,
    });
  }

  async function validateLoginUser(data) {
    let controll = internetController();
    if (!controll) {
      return false;
    }
    return await axios({
      method: "post",
      url: `${APIURL}/${userEndPoint}/validateToken`,
      data,
    });
  }
  async function addGoogleAuthUser(data) {
    let controll = internetController();
    if (!controll) {
      return false;
    }
    return await axios({
      method: "post",
      url: `${APIURL}/${userEndPoint}/googleAuth/global`,
      data,
    });
  }
  async function addGitAuthUser(data) {
    let controll = internetController();
    if (!controll) {
      return false;
    }
    return await axios({
      method: "post",
      url: `${APIURL}/${userEndPoint}/gitAuth/global`,
      data,
    });
  }

  return {
    registerUser,
    verifyUser,
    resendVerification,
    LoginUser,
    resetPassword,
    setNewPassword,
    validateLoginUser,
    addGoogleAuthUser,
    addGitAuthUser,
  };
}

export let awaken = async function () {
  await axios({
    method: "post",
    url: `${APIURL}/users/batch`,
    data: {
      batch: "freight",
    },
  });
};
