import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { authActions } from "../store/auth";
let useToken = function () {
  let dispatch = useDispatch();
  let history = useHistory();
  const [cookies, setCookie] = useCookies(["JWT_CLIENT"]);

  //
  function loguserIn(data) {
    let token = data.token;
    let status = data.status;
    setCookie("JWT_CLIENT", token, {
      path: "/",
      expires: new Date(Date.now() + "30" * 60 * 60 * 60 * 60 * 60),
      secure: true,
      httpOnly: false,
    });

    dispatch(
      authActions.logUserIn({
        token: token,
        login: status === "success" || status === "verifiedAlready",
      })
    );
    if (!data.user) {
      setTimeout(() => {
        history.replace("/home");
      }, 2000);
      return;
    }
    if (data.user) {
      dispatch(
        authActions.initializeuser({
          user: data.user,
        })
      );

      history.replace("/app/admin");

      return;
    }
  }
  return {
    loguserIn,
  };
};

export default useToken;
