import { useSelector } from "react-redux";
let defaultURL = "https://techkey.web.app";
export default function useRedirect(props) {
  let user = useSelector(function (store) {
    return store.auth;
  });
  let redirectUrl = user.redirectUrl || defaultURL;

  if (user.isLoggedin && user.loginAuthToken != "") {
    //rediirect
    window.open(
      `${redirectUrl}/globalAuth?token=${user.loginAuthToken}&access=true`,
      // `https://techkey.web.app/globalAuth?token=${user.loginAuthToken}&access=true`,
      "_self"
    );
  }
  return {
    appredirectUrl: `${redirectUrl}/globalAuth?token=${user.loginAuthToken}&access=true`,
  };
}
