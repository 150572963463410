import { Fragment, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  AccountCircle,
  RemoveRedEye,
  Login as LoginIcon,
  Email,
  Lock,
  HourglassBottom,
  LockOpen,
} from "@mui/icons-material";
import FormLogo from "./formLogo";
import Footer from "./footer";
import { showToast } from "../utils/toasts";
import useToken from "../hooks/useToken";
import { useHistory } from "react-router-dom";
import { UserEndpoint } from "../utils/ApiHandler";
export default function ResetPassword(props) {
  let { token } = props;
  let { changeView, setnew } = props;
  let [value, setValue] = useState("");
  let history = useHistory();
  let { resetPassword, setNewPassword } = UserEndpoint();
  let emailInput = useRef();
  let [isloading, setIsloading] = useState(false);

  function showError(message, errorType, pass) {
    if (!pass) {
      showToast({
        message: message,
        type: "danger",
      });
    }
    errorType(() => {
      return { status: !pass, message: message };
    });
  }
  async function submitHandler(event) {
    event.preventDefault();

    if (isloading) {
      return;
    }

    if (
      emailInput.current.value.trim().length == 0 ||
      !emailInput.current.value.trim().includes("@")
    ) {
      return showToast({
        message: "Kindly provide a valid Email",
        type: "error",
      });
    }

    setIsloading(true);
    try {
      let res = await resetPassword({ email: emailInput.current.value });

      if (res.status === 200 && res.data.status === "success") {
        showToast({
          message: "Verification code sent, Please check your Email",
        });

        setIsloading(false);
        emailInput.current.value = "";

        return;
      }

      let error = res.data.message || "Could not send reset details";
      showToast({
        message: error,
        type: "danger",
      });

      setIsloading(false);
    } catch (error) {
      console.log("CRASHING");
      setIsloading(false);
      showToast({
        message: "An error occured, try again later",
        type: "danger",
      });
    }
  }

  let [passwordError, setpasswordError] = useState({
    status: false,
    message: "",
  });

  let [password, setpassword] = useState("");
  let [confirmPassword, setconfirmPassword] = useState("");

  async function resetPasswordHandler(event) {
    event.preventDefault();

    if (isloading) {
      return;
    }

    if (password == "" || confirmPassword == "") {
      showToast({
        message: "Kindly set both password",
        type: "danger",
      });

      return;
    }

    if (password.length < 8) {
      showToast({
        message: "Password length must to be greater than 8",
        type: "danger",
      });

      return;
    }
    if (!(password === confirmPassword)) {
      showToast({
        message: "passwords do not match",
        type: "danger",
      });

      return;
    }

    try {
      setIsloading(true);

      let res = await setNewPassword({
        token,
        password,
        passwordConfirm: confirmPassword,
      });

      if (res.status === 200 && res.data.status === "success") {
        setIsloading(false);
        showToast({
          message: "Password has been reset",
        });
        changeView();
        history.replace("/");

        return;
      }

      let error = res.data.message || "Passsword reset failed, try again later";
      showToast({
        message: error,
        type: "danger",
      });

      setIsloading(false);

      if (res.data.message.includes("Token is Invalid")) {
        setpasswordError(function () {
          return { status: true, message: res.data.message };
        });
      }
    } catch (error) {
      showToast({
        message: "An error occured, try again later",
        type: "danger",
      });

      setIsloading(false);
    }
  }

  return (
    <form
      action="index.html"
      autoComplete="off"
      className="sign-in-form animate__animated animate__bounce"
    >
      <FormLogo />

      <div className="heading">
        <h2>Restore your account</h2>
        <br />
        {!setnew && <h6>We shall send a verification code to your email.</h6>}
        {setnew && <h6>Set a new password below.</h6>}
      </div>

      <div className="actual-form">
        {!setnew && (
          <div className="input-wrap">
            <input
              ref={emailInput}
              type="text"
              minLength="4"
              className={`input-field ${
                value.trim().length > 0 ? "active" : ""
              }`}
              onChange={function (e) {
                e.preventDefault();
                setValue(e.target.value);
              }}
              autoComplete="off"
              required
            />
            <label> Enter Email</label>
          </div>
        )}

        {setnew && (
          <Fragment>
            <div className="input-wrap">
              <input
                type="password"
                minLength="4"
                className={`input-field ${
                  password.trim().length > 0 ? "active" : ""
                }`}
                onChange={function (e) {
                  e.preventDefault();
                  setpassword(e.target.value);
                }}
                autoComplete="off"
                required
              />
              <label> Set a new password</label>
            </div>
            <div className="input-wrap">
              <input
                type="password"
                minLength="4"
                className={`input-field ${
                  confirmPassword.trim().length > 0 ? "active" : ""
                }`}
                onChange={function (e) {
                  e.preventDefault();
                  setconfirmPassword(e.target.value);
                }}
                autoComplete="off"
                required
              />
              <label> confirm Password Email</label>
            </div>
          </Fragment>
        )}

        <p className="text forgot-text">
          <a
            style={{
              textDecoration: "none",
              color: "blue",
            }}
            onClick={function (e) {
              e.preventDefault();
              changeView();
            }}
            href="#"
          >
            Back to Login
          </a>
        </p>

        <button
          onClick={setnew ? resetPasswordHandler : submitHandler}
          className="flex-con sign-btn"
        >
          {!isloading && (
            <Fragment>
              {!setnew && "Reset Password "}
              {setnew && "Save Password "}
              <LockOpen />
            </Fragment>
          )}
          {isloading && "Loading... "}
          {isloading && <HourglassBottom />}
        </button>

        <p className="text">
          By using our platforms, you agree to our{" "}
          <a className="terms-link" href="https://techkey.co.ke/terms">
            Terms of Services
          </a>{" "}
          and{" "}
          <a className="terms-link" href="https://techkey.co.ke/policy">
            Privacy Policy
          </a>
        </p>
      </div>
    </form>
  );
}
