import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { useParams } from "react-router-dom";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer } from "react-toastify";
import Carousel from "../components/carousel";
import Login from "../components/login";
import Register from "../components/register";
import ResetPassword from "../components/resetPassword";
import VerifyCode from "../components/verifycode";
import { APP_CODES } from "../store/appCodes";
import { authActions } from "../store/auth";

export default function Auth(props) {
  let { appUser ,disposeUser} = props;
  let dispatch = useDispatch();
  let [login, setLogin] = useState(true);
  let [forgotPass, setforgotPass] = useState(false);
  let [resetPassword, setresetPassword] = useState(false);
  let [verifyCode, setverifyCode] = useState(false);
  let [token, setToken] = useState("");
  let [idToken, setidToken] = useState("");

  let location = useLocation();

  useEffect(
    function () {
      if (location.pathname == "/signup") {
        setLogin(false);
      }
      if (location.pathname == "/forgot") {
        setforgotPass(true);
      }
      if (location.pathname == "/validate") {
        let requesttoken = new URLSearchParams(location.search).get("request");
        if (requesttoken && String(requesttoken).length != 0) {
          setToken(requesttoken);
          setresetPassword(true);
          setforgotPass(true);
        }
      }
      if (location.pathname == "/regVerify") {
        let codeID = new URLSearchParams(location.search).get("input");
        setverifyCode(true);
        setLogin(false);
        setidToken(codeID);
      }
      if (location.pathname == "/platform") {
        let appCode = new URLSearchParams(location.search).get("appcode");

        let platform = APP_CODES.filter(function (item) {
          return item.code == appCode;
        });
        if (platform.length > 0) {
          dispatch(authActions.setRedirect(platform[0].url));
        }
      }
    },
    [location.pathname]
  );

  function toggleVerification(e) {
    setverifyCode(true);
  }

  function toggleLogin(e) {
    setLogin(function (prevData) {
      return !prevData;
    });
  }
  function resetVerification(e) {
    setverifyCode(false);
    setLogin(true);
  }
  function toggleReset(e) {
    setforgotPass(function (prevData) {
      return !prevData;
    });
  }

  return (
    <main className={login ? "" : "sign-up-mode"}>
      <div className="box">
        <div className="inner-box">
          <div className="forms-wrap">
            {!forgotPass && (
              <Login  disposeUser={disposeUser} appUser={appUser} changeView={toggleLogin} resetView={toggleReset} />
            )}
            {forgotPass && (
              <ResetPassword
                token={token}
                setnew={resetPassword}
                changeView={toggleReset}
              />
            )}
            {!verifyCode && (
              <Register verify={toggleVerification} changeView={toggleLogin} />
            )}
            {verifyCode && (
              <VerifyCode idToken={idToken} changeView={resetVerification} />
            )}
          </div>

          <Carousel />
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        limit={3}
      />
    </main>
  );
}
