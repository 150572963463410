export default function FormLogo(props) {
  return (
    <div className="logo">
      <img src="./img/logo.svg" alt="easyclass" />
      <h4 className="form-heading text-format-space">
        One account for everything
      </h4>
    </div>
  );
}
