import { Fragment, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  AccountCircle,
  RemoveRedEye,
  Login as LoginIcon,
  Email,
  Lock,
  HourglassBottom,
  LockOpen,
} from "@mui/icons-material";
import FormLogo from "./formLogo";
import Footer from "./footer";
import { showToast } from "../utils/toasts";
import useToken from "../hooks/useToken";
import { useHistory } from "react-router-dom";
import { UserEndpoint } from "../utils/ApiHandler";
import { useDispatch } from "react-redux";
import { authActions } from "../store/auth";
export default function VerifyCode(props) {
  let { idToken } = props;
  let { changeView, setnew } = props;
  let [value, setValue] = useState("");
  let history = useHistory();
  let dispatch = useDispatch();
  let { verifyUser } = UserEndpoint();
  let verificationInput = useRef();
  let [isloading, setIsloading] = useState(false);

  function showError(message, errorType, pass) {
    if (!pass) {
      showToast({
        message: message,
        type: "danger",
      });
    }
    errorType(() => {
      return { status: !pass, message: message };
    });
  }
  async function submitHandler(event) {
    event.preventDefault();
    setIsloading(true);
    let code = verificationInput.current.value;

    if (code.trim().length == 0) {
      setIsloading(false);
      return showToast({
        message: "Kindly provide the verification code",
        type: "danger",
      });
    }

    let data = {
      id: idToken,
      code,
    };
    try {
      let res = await verifyUser(data);

      if (res.status === 200 && res.data.status === "success") {
        showToast({
          message: "Verification Successfull",
        });

        setIsloading(false);
        history.push("/");
        changeView();

        /*
        setCookie("JWT_CLIENT", res.data.token, {
          path: "/",
          expires: new Date(Date.now() + "30" * 60 * 60 * 60 * 60 * 60),
          secure: true,
          httpOnly: false,
        });*/

        dispatch(
          authActions.logUserIn({
            token: res.data.token,
            login: res.data.status === "success",
          })
        );

        return;
      }
      let error = res.data.message || "Could not Validate";
      showToast({
        message: error,
        type: "error",
      });

      setIsloading(false);
    } catch (error) {
      showToast({
        message: "An error occured, try again later",
        type: "error",
      });

      setIsloading(false);
    }
  }

  return (
    <form action="index.html" autoComplete="off" className="sign-up-form">
      <FormLogo />

      <div className="heading">
        <h2>Verify your account</h2>
        <br />
        <h6>Provide the verification code sent to your email.</h6>
      </div>

      <div className="actual-form">
        <div className="input-wrap">
          <input
            ref={verificationInput}
            type="text"
            minLength="4"
            className={`input-field ${value.trim().length > 0 ? "active" : ""}`}
            onChange={function (e) {
              e.preventDefault();
              setValue(e.target.value);
            }}
            autoComplete="off"
            required
          />
          <label> Verification Code</label>
        </div>

        <p className="text forgot-text">
          <a
            onClick={function (e) {
              e.preventDefault();
              changeView();
            }}
            href="#"
          >
            Back to Login
          </a>
        </p>

        <button onClick={submitHandler} className="flex-con sign-btn">
          {!isloading && (
            <Fragment>
              {!isloading && "Verify"}
              {!isloading && <AccountCircle />}
            </Fragment>
          )}
          {isloading && "Loading... "}
          {isloading && <HourglassBottom />}
        </button>

        <p className="text">
          By using our platforms, you agree to our{" "}
          <a className="terms-link" href="https://techkey.co.ke/terms">
            Terms of Services
          </a>{" "}
          and{" "}
          <a className="terms-link" href="https://techkey.co.ke/policy">
            Privacy Policy
          </a>
        </p>
      </div>
    </form>
  );
}
