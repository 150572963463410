import { createSlice } from "@reduxjs/toolkit";
let API_PORT = "5501";
/*
  Deploy site :  firebase deploy --only hosting:techkey  
  VERSION : Change on code
 */
// let STAGE = "development";

let STAGE = "production";

export let appVersion = "V1.1.0";

export let APIURL =
  STAGE == "development"
    ? `http://localhost:${API_PORT}/api/v1`
    : "https://cybernetics-backend.herokuapp.com/api/v1";
export let IMAGE_URL =
  STAGE == "development"
    ? `http://localhost:${API_PORT}/assets/images/users`
    : "https://cybernetics-backend.herokuapp.com/assets/images/users";
export let INVOICE_URL =
  STAGE == "development"
    ? `http://localhost:${API_PORT}/invoices`
    : "https://cybernetics-backend.herokuapp.com/invoices";
//

export let authPublicKey =
  "j13f052ae923c03f5b12aedc6cca49874c1c9b0ccf3f39b662d1b";
let initialState = {
  idToken: "",
  isLoggedin: false,
  loginAuthToken: "",
  appUser: false,
  redirectUrl: "",
};

let authSplice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    setTempUser(state, action) {
      state.idToken = action.payload.token;
    },
    logUserIn(state, action) {
      state.loginAuthToken = action.payload.token;
      state.isLoggedin = action.payload.login;
    },
    initializeuser(state, action) {
      state.appUser = action.payload.user;
    },
    loguserOut(state) {
      state.loginAuthToken = "";
      state.isLoggedin = false;
    },
    setRedirect(state, action) {
      state.redirectUrl = action.payload;
    },
  },
});

export let authActions = authSplice.actions;

let authReducer = authSplice.reducer;

export default authReducer;
