import { Fragment, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  AccountCircle,
  VisibilityOff,
  RemoveRedEye,
  Email,
  Lock,
  HowToReg,
  HourglassBottom,
  Forward,
  ArrowForward,
  ArrowBack,
} from "@mui/icons-material";
import FormLogo from "./formLogo";
import Footer from "./footer";
import { UserEndpoint } from "../utils/ApiHandler";
import { useDispatch } from "react-redux";
import { authActions } from "../store/auth";
import { showToast } from "../utils/toasts";

export default function Register(props) {
  let [step, setStep] = useState("1");
  let [getConfirmPassword, setgetConfirmPassword] = useState(false);
  let history = useHistory();
  let { changeView, verify } = props;
  let { registerUser } = UserEndpoint();
  let [isloading, setIsLoading] = useState(false);
  let dispatch = useDispatch();
  // input state
  //name
  let [name, setname] = useState("");
  let [email, setemail] = useState("");
  let [password, setpassword] = useState("");
  let [passwordConfirm, setpasswordConfirm] = useState("");

  let [nameError, setnameError] = useState({ status: false, message: "" });
  let [emailError, setemailError] = useState({ status: false, message: "" });
  let [passwordError, setpasswordError] = useState({
    status: false,
    message: "",
  });
  let [passwordConfirmError, setpasswordConfirmError] = useState({
    status: false,
    message: "",
  });

  function showError(message, errorType, pass) {
    if (!pass) {
      showToast({
        message: message,
        type: "danger",
      });
    }
    errorType(() => {
      return { status: !pass, message: message };
    });
  }
  function getConfirmationHandler(event) {
    event.preventDefault();
    //all okay
    let NoformError =
      !passwordError.status && !emailError.status && !nameError.status;

    let formPopulated =
      name.length > 0 && email.length > 0 && password.length > 0;

    if (name.trim().split(" ").length < 2) {
      return showError("Kindly provide your second name", setnameError);
    }
    if (name.trim().split(" ")[0].length < 4) {
      return showError("Your first name is too short ", setnameError);
    }
    if (name.trim().split(" ")[1].length < 4) {
      return showError("Your second name is too short ", setnameError);
    }
    showError("", setnameError, true);

    if (!email.includes("@")) {
      return showError("Kindly provide a valid email", setemailError);
    }
    showError("", setemailError, true);

    if (password.length < 8) {
      return showError(
        "Password length must to be greater than 8",
        setpasswordError
      );
    }
    showError("", setpasswordError, true);

    //form validated

    let formIsValid = NoformError && formPopulated;

    if (formIsValid) {
      setgetConfirmPassword(true);
    }
  }

  async function submitHandler(event) {
    event.preventDefault();

    if (isloading) {
      return;
    }

    setIsLoading(true);

    //all okay
    let NoformError =
      !passwordConfirmError.status &&
      !passwordError.status &&
      !emailError.status &&
      !nameError.status;

    let formPopulated =
      name.length > 0 &&
      email.length > 0 &&
      password.length > 0 &&
      passwordConfirm.length > 0;

    //similar passwords

    if (password !== passwordConfirm) {
      showError("Passwords dont match", setpasswordConfirmError);
      setIsLoading(false);
      return;
    }
    showError("", setpasswordConfirmError, true);

    //form validated
    let formIsValid = NoformError && formPopulated;

    if (formIsValid) {
      let data = {
        email,
        name,
        password,
        passwordConfirm,
      };

      try {
        let res = await registerUser(data);

        if (res.status === 200 && res.data.status === "success") {
          showToast({
            message: "Sign up Success",
          });

          setIsLoading(false);
          dispatch(
            authActions.setTempUser({
              token: res.data.id,
            })
          );
          history.push(`/regVerify?input=${res.data.id}`);
          verify();

          //verify user email

          setTimeout(function () {
            props.loadVerification();
          }, 2000);

          return;
        }
        let error = res.data.error || "Sign up failed, try again later";
        showToast({
          message: error,
          type: "error",
        });

        setIsLoading(false);
        return;
      } catch (error) {
        showToast({
          message: "An error occured, try again later",
          type: "error",
        });

        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }

  return (
    <form action="index.html" autoComplete="off" className="sign-up-form">
      <FormLogo />

      <div className="heading">
        <h2 className="text-format-space">Get Started</h2>
        <br />
        <h6 className="text-format-space">Have an account?</h6>
        <a
          onClick={function (e) {
            e.preventDefault();
            changeView();
          }}
          href="#"
          className="toggle"
        >
          {" "}
          Sign in
        </a>
        <br />
        <br />
      </div>

      <div className="actual-form">
        {!getConfirmPassword && (
          <Fragment>
            <div className="input-wrap">
              <input
                type="text"
                minLength="4"
                className={`input-field ${name.length > 0 ? "active" : ""} ${
                  nameError.status ? "inputerror" : ""
                } `}
                autoComplete="off"
                data-name="Fname"
                value={name}
                required
                onChange={function (e) {
                  let value = e.target.value;
                  setname(value);
                }}
              />
              <label>Name</label>
            </div>

            <div className="input-wrap">
              <input
                type="email"
                className={`input-field ${email.length > 0 ? "active" : ""} ${
                  emailError.status ? "inputerror" : ""
                }`}
                autoComplete="off"
                required
                value={email}
                data-name="email"
                onChange={function (e) {
                  let value = e.target.value;
                  setemail(value);
                }}
              />
              <label>Email</label>
            </div>

            <div className="input-wrap">
              <input
                type="password"
                minLength="4"
                className={`input-field ${
                  password.length > 0 ? "active" : ""
                } ${passwordError.status ? "inputerror" : ""}`}
                autoComplete="off"
                value={password}
                required={true}
                data-name="password"
                onChange={function (e) {
                  let value = e.target.value;
                  setpassword(value);
                }}
              />
              <label>Password</label>
            </div>
          </Fragment>
        )}
        {getConfirmPassword && (
          <div
            style={{ paddingBottom: "1rem" }}
            className="animate__animated animate__bounce input-wrap"
          >
            <input
              type="password"
              value={passwordConfirm}
              minLength="4"
              className={`input-field ${
                passwordConfirmError.status ? "inputerror" : ""
              }
              ${passwordConfirm.length > 0 ? "active" : ""}`}
              autoComplete="off"
              required={true}
              data-name="confirmpass"
              onChange={function (e) {
                let value = e.target.value;
                setpasswordConfirm(value);
              }}
            />
            <label>Confirm Your Password </label>
          </div>
        )}
        {getConfirmPassword && (
          <a
            href="#"
            className="backBtn"
            onClick={function (e) {
              e.preventDefault();
              setgetConfirmPassword(false);
            }}
          >
            &larr; Go back
          </a>
        )}
        <button
          onClick={!getConfirmPassword ? getConfirmationHandler : submitHandler}
          disabled={isloading}
          type="submit"
          className={`flex-con sign-btn ${
            !getConfirmPassword ? "hover-green" : ""
          } `}
        >
          {!getConfirmPassword && !isloading && "Next "}

          {!getConfirmPassword && !isloading && <ArrowForward />}
          {getConfirmPassword && !isloading && "Sign Up "}
          {getConfirmPassword && !isloading && <HowToReg />}
          {isloading && "Loading..."}
          {isloading && <HourglassBottom />}
        </button>

        <Footer />
      </div>
    </form>
  );
}
